import LandingPage from './landing/LandingPage';
import VouchersPage from './vouchers/VouchersPage';
import BookNow from './booking/BookNow';
import BookingPage from './booking/Booking';
import AboutPage from './about/AboutPage';
import CoursesPage from './courses/CoursesPage';
import ReviewsPage from './reviews/ReviewsPage';
import ContactPage from './../views/contact/ContactPage';
import TreatmentsPage from './treatments/TreatmentsPage';
import AntiWrinklePage from './treatments/AntiWrinklePage/index';
import CleftLipRepairPage from './treatments/CleftLipRepairPage/index';
import DermalLipFillerPage from './treatments/DermalLipFillerPage/index';
import DesofacePage from './treatments/DesofacePage/index';
import DissolvingFillerPage from './treatments/DissolvingFillerPage/index';
import PdoThreadsPage from './treatments/PdoThreadsPage/index';
import PrgfPage from './treatments/PrgfPage/index';
import SkinBoostersPage from './treatments/SkinBoostersPage/index';

import ErrorPage from './ErrorPage';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

export default function Routes() {
    return (

        <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route path="/" exact component={LandingPage} />
                <Route path="/voucher" exact component={VouchersPage} />
                <Route path="/book-now" exact component={BookNow} />
                <Route path="/booking/:slug" exact component={BookingPage} />
                <Route path="/about" exact component={AboutPage} />
                <Route path="/courses" exact component={CoursesPage} />
                <Route path="/reviews" exact component={ReviewsPage} />
                <Route path="/contact-us" exact component={ContactPage} />
                <Route path="/treatments/anti-wrinkle-injection" exact component={AntiWrinklePage} />
                <Route path="/treatments/cleft-lip-repair" exact component={CleftLipRepairPage} />
                <Route path="/treatments/dermal-and-lip-filler" exact component={DermalLipFillerPage} />
                <Route path="/treatments/fat-dissolving-injection" exact component={DesofacePage} />
                <Route path="/treatments/dissolving-filler" exact component={DissolvingFillerPage} />
                <Route path="/treatments/pdo-threads" exact component={PdoThreadsPage} />
                <Route path="/treatments/plasma-rich-in-growth-factors" exact component={PrgfPage} />
                <Route path="/treatments/skinboosters" exact component={SkinBoostersPage} />
                <Route path="/treatments" exact component={TreatmentsPage} />

                <Route path="*" exact component={ErrorPage} />
            </Switch>
        </BrowserRouter>
    );
}