import React from 'react';

const BlankLayout = ({ children }) => {
    return (
        <>
            <div id="Wrapper">

                {children}

            </div>

            <div id="Side_slide" className="right dark" data->
                <div className="close-wrapper">
                    <a href="#" className="close"><i className="icon-cancel-fine"></i></a>
                </div>
                <div className="extras">
                    <div className="extras-wrapper"></div>
                </div>
                <div className="menu_wrapper"></div>
            </div>
            <div id="body_overlay"></div>
        </>
    );
}

export default BlankLayout;
