import React from 'react';

import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer id="Footer" className="clearfix">
            <div className="widgets_wrapper theme-bakground">
                <div className="container">
                    <div className="column_attr clearfix align_center hrmargin_b_20">
                        <h3>LET'S CONNECT</h3>
                        <h4 className="text-light">
                            Our mission is to help you look more beautiful. We're happy to help.
                        </h4>
                        <div className="column mcb-column mcb-item-7mxcewd8r one column_button">
                            <div className="hrpadding_b_30">
                                <Link to="/contact-us" className="button button_size_2 button_theme_dark button_js">
                                    <span className="button_label text-light">
                                        CONTACT US
                                </span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="column_attr clearfix align_center">
                        <aside className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <p style={{ fontSize: "50px" }}>
                                    <a href="https://www.instagram.com/sitaramedclinic/?hl=en" target="_blank" rel="noreferrer">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    <a href="https://www.facebook.com/SitaraMedicalClinic/" target="_blank" rel="noreferrer">
                                        <i className="icon-facebook-circled"></i>
                                    </a>
                                </p>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <div className="widgets_wrapper white-bakground">
                <div className="container">

                    <div className="column one-third">
                        <aside className="widget_text widget widget_custom_html">
                            <h4>About Us</h4>
                            <div className="textwidget custom-html-widget">
                                <h5 style={{ marginBottom: "3px", marginTop: "5px" }}>The Company</h5>
                                <ul>
                                    <li>
                                        <Link to="/contact-us">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/courses">
                                            Courses
                                        </Link>
                                    </li>
                                </ul>
                                <h5 style={{ marginBottom: "3px", marginTop: "5px" }}>Email</h5>
                                <p>
                                    <a href="mailto:sitaramedclinic@gmail.com">
                                        sitaramedclinic@gmail.com
                                    </a>
                                </p>
                                <img src="/assets/images/logo/sitara-clinic.png" alt="" width="100%" height="auto" style={{ display: "block", maxWidth: "250px" }} />
                            </div>
                        </aside>
                    </div>

                    <div className="column one-third">
                        <aside className="widget_text widget widget_custom_html">
                            <h4>Treatments</h4>
                            <div className="textwidget custom-html-widget">
                                <ul>
                                    <li>
                                        <Link to="/treatments">
                                            Anti-Wrinkle Injections for Women
                                        </Link>

                                    </li>
                                    <li>
                                        <Link to="/treatments">
                                            Anti-Wrinkle Injections for men
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/treatments">
                                            Dermal Filler
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/treatments">
                                            Lip Filler
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/treatments">
                                            Tear Trough Filler
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/treatments">
                                            Double-Chin Fat Dissolving
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/treatments">
                                            Gummy Smile Dissolving
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/treatments">
                                            Threadlifting
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>

                    <div className="column one-third">
                        <aside className="widget_text widget widget_custom_html">
                            <h4>Locations</h4>
                            <div className="textwidget custom-html-widget">
                                <ul>
                                    <li>Dublin</li>
                                    <li>Newry</li>
                                    <li>Carlow</li>
                                    <li>Waterford</li>
                                    <li>Clonmel</li>
                                    <li>Gorey</li>
                                </ul>
                            </div>
                        </aside>
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
