import React from 'react';
import DefaultLayout from '../../layout/DefautlLayout';
import Header from '../../components/header/Header';
import styled from './treatment.module.css';

const TreatmentsPage = () => {
    return (
        <DefaultLayout>
            <Header />
            <div id="Content">
                <div className="content_wrapper clearfix">
                    <div className="sections_group">
                        <div className="extra_content">
                            <div className={`section mcb-section mcb-section-1186132e1 bg-cover`}
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    backgroundImage: "url(/assets/images/banners/treatment-xl-2.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "right bottom"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
                                        style={{
                                            padding: "30% 0 10%"
                                        }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
                                                <div className={`clearfix ${styled.bannerTextWidth}`}>
                                                    <h2>Treatments</h2>
                                                    <hr className="no_line"
                                                        style={{
                                                            margin: "0 auto 15px"
                                                        }} />
                                                    <p>
                                                        Following a consultation, here are some of the treatments we offer at Sitara Clinic, from Anti-Wrinkle treatment for women and men, to dermal and lip fillers, PRP and Skinboosters, we will definitely have atreatment to suit you and help you glow.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styled.pusherDiv}></div>

                            <div className="section center bg-theme hrmargin_b_60" style={{ paddingTop: "30px", paddingBottom: "15px" }}>
                                <div className="section_wrapper clearfix">
                                    <div className="items_group clearfix">
                                        <div className="column_attr">
                                            <h3 className="text-light" style={{ letterSpacing: "3px" }}>
                                                Anti-wrinkle injections
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section mcb-section" id="image" style={{
                                paddingBottom: "70px"
                            }}>
                                <div className="section" id="image flv_sections_16">
                                    <div className="items_group clearfix">

                                        <div className="section_wrapper hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>

                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/1a.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        Anti-Wrinkle Injections
                                                    </h3>
                                                    <p>
                                                        Botulinum Toxin is a purified protein which can be injected into facial muscles for anti-ageing purposes. This treatment can be preventative as well as to treat existing wrinkles. It works by relaxing over-active muscles of the face. The most common areas to treat are the forehead, frown, crows feet, bunny lines, smoker lines and corner of the mouth. Botulinum toxin is also used to treat migraines, teeth grinding, excessive sweating (face or underarm), platysmal neck bands, and gummy smile. The results take 2 weeks to take effect, and generally lasts 3-4 months.
                                                    </p>
                                                    <div className="button_align align_right">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/anti-wrinkle-injection">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                        <div className="section_wrapper d-md-flex row-reverse hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/2a.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        Dermal and Lip Filler
                                                    </h3>
                                                    <p>
                                                        Certainly, there has been a huge rise in the past few years in dermal and lip filler. The filler used nowadays is hyaluronic acid, which is a natural sugar found in the body. This certainly reduces the risks of reactions to the product. Filler can be used for several purposes; whether it is to plump the lips or under-eyes (tear trough), contour the cheekbones or jawline, define the nose, or to fill folds on the face. Some fillers also have lifting properties. 80% of the results are visible immediately after treatment, whereas the full result can be seen in a week. Lip filler can last 6-9 months, whereas dermal filler can last 9-12 months. At Sitara Medical Clinic, we prefer the Restylane and Teosyal filler range.
                                                    </p>
                                                    <div className="button_align">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/dermal-and-lip-filler">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                        <div className="section_wrapper hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/3a.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        Skinboosters
                                                    </h3>
                                                    <p>
                                                        Whether you are someone who is not quite ready to get anti-wrinkle treatment and filler, or you are someone who is looking for something extra to further treat your skin - skinbooster is a fantastic solution. It is non-crosslinked hyaluronic acid (as opposed to filler, which is cross-linked hyaluronic acid). This difference means that it doesn’t change your appearance, but rather plumps and hydrates the skin. Skinboosters has also been referred to as “baby filler” or “the injectable facial”. The treatment involves placing small blebs of skinbooster into a specific layer of your skin which produces hydration. This treatment is ideal for treatment of dry, dull skin and fine lines. The recommendation with skinbooster is to have three sessions, one month apart. We like Teoxane Redensity 1 Skinbooster at our clinic, but Profhilo is also available.
                                                    </p>
                                                    <div className="button_align align_right">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/skinboosters">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                        <div className="section_wrapper d-md-flex row-reverse hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/4a.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        Plasma Rich in Growth Factors (PRGF)
                                                    </h3>
                                                    <p>
                                                        PRGF is an advanced medical version of PRP (the vampire facial). This procedure has been around in regenerative medicine for a long time - in oral surgery, orthopaedic surgery, sports medicine, dermatology etc. It involves separating growth factors from your blood to inject into either the face or scalp. In over 1 million procedures performed worldwide, there has been no report of adverse reaction to this procedure. This is because the product injected comes from your own body, which reduces the chances of rejection. This is reassuring for someone who might be nervous to get started with cosmetic treatment. Injections to the scalp promote hair growth, and the face is injected for anti-ageing purposes. This treatment is particularly effective under the eyes. PRGF can also be combined with other treatments at our clinic such as skin boosters and micro-needling. The micro-needling option is great for someone who suffers with acne.
                                                    </p>
                                                    <div className="button_align">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/plasma-rich-in-growth-factors">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                        <div className="section_wrapper hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/5a.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        PDO Threads
                                                    </h3>
                                                    <p>
                                                        Polydioxanone (PDO) threads are special “suture-like” threads, made of protein. When this thread is placed in the skin, it begins a process to produce collagen. Collagen is what we naturally lose in our skin, which is why our skin loses elasticity. There are two types of PDO threads - smooth mono-threads, or barbed threads. The smooth threads work to rejuvenate the skin and also has a lipolytic quality (destroys fat cells). This is ideal for areas such as the jawline, under the chin, and smile lines. The barbed threads have a pulling quality, which can lift loose skin for some time. This is often used to lift the brows, the cheeks or the jawline/jowl. Both threads dissolve naturally and leave a layer of collagen behind in the skin.
                                                    </p>
                                                    <div className="button_align align_right">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/pdo-threads">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                        <div className="section_wrapper d-md-flex row-reverse hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/6a-1.jpg" alt="Desoface" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        Fat Dissolving Injections (Desoface)
                                                    </h3>
                                                    <p>
                                                        Desoface (Deoxycholate acid) is designed to dissolve stubborn fat pockets. This treatment is for the under-chin area. In the correct patient, this can reduce fat permanently by 30-40%.
                                                    </p>
                                                    <div className="button_align">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/fat-dissolving-injection">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                        <div className="section_wrapper hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/7a.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        Cleft-Lip Repair
                                                    </h3>
                                                    <p>
                                                        As part of our pro-bono work, we repair cleft-lip scarring free of charge. We break up the scar tissue using a cannula, and place a soft filler to redefine the lip border, making the cleft lip less noticeable. Please contact us today if you think that we might be able to help you!
                                                    </p>
                                                    <div className="button_align align_right">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/cleft-lip-repair">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                        <div className="section_wrapper d-md-flex row-reverse hrmargin_b_30">
                                            <div className="column one-third column_image">
                                                <div className="image_frame scale-with-grid has_border aligncenter">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" width="100%" height="auto" src="/assets/images/treatments/8a.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column two-third column_column">
                                                <div className="column_attr">
                                                    <h3>
                                                        Dissolving Filler
                                                    </h3>
                                                    <p>
                                                        Hyaluronic filler can be dissolved using an enzyme called Hyaluronidase. Whether you feel like your filler has migrated from its original place, you have a persistent lump of filler or if you just don’t like/want filler anymore - this procedure can reverse its effects almost instantly. Filler can also be dissolved in case of an emergency. This has become particularly common with the rise in non-medical practitioners carrying out filler procedures. If you feel like you might be having a filler-related emergency, please contact us immediately.
                                                    </p>
                                                    <div className="button_align">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/treatments/dissolving-filler">
                                                            <span className="button_label">
                                                                PRICES & RESULTS
                                                        </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column one column_divider">
                                            <hr className="no_line" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}

export default TreatmentsPage;