import React, { useState, useEffect } from 'react';
import BlankLayout from '../../layout/BlankLayout';
import Header from '../../components/header/Header';
import { useParams, Link } from 'react-router-dom';
import styled from './style.module.css';

const locations = {
    "ireland-location": 'https://sitaramedicalcliniccarlow.phorest.me/locations',
    "uk-location": 'https://sitaramedicalnewry.phorest.me/locations'
};

const LocationPage = (props) => {
    const { slug } = useParams();
    const [iframeSrc, updateSrc] = useState("");

    useEffect(() => {
        updateSrc(locations[slug]);
    }, [slug]);

    return (

        <BlankLayout>
            <Header />
            <div className={styled.wrapperDiv}>
                {
                    iframeSrc ?
                        <iframe title="booking iframe" src={iframeSrc} width="100%" style={{ minHeight: "100%" }}></iframe>
                        :
                        <div style={{ margin: "auto" }}>
                            <h4>Location not found</h4>
                            <Link to="/book-now">Return</Link>
                        </div>
                }

            </div>
        </BlankLayout>
    );
}

export default LocationPage;