import React from 'react';
import BlankLayout from '../../layout/BlankLayout';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';

const BookNowPage = (props) => {
    return (

        <BlankLayout>
            <Header />
            <div id="Content">
                <div className="content_wrapper clearfix">
                    <div className="sections_group">
                        <div className="extra_content">

                            <div className="section mcb-section" id="image"
                                style={{
                                    paddingTop: "110px",
                                    paddingBottom: "70px",
                                    minHeight: "50vh",
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center"
                                }}>

                                <div className="section_wrapper mcb-section-inner pe-va">
                                    <div className="wrap mcb-wrap one valign-top clearfix">
                                        <div className="column mcb-column one column_column">
                                            <div className="column_attr align_center clearfix">
                                                <h1>
                                                    Book now
                                                </h1>
                                                <h4>
                                                    Select the location nearest to you
                                                </h4>
                                            </div>
                                        </div>

                                        <div className="column mcb-column one column_column">
                                            <div className="column_attr align_center">
                                                <Link className="button button_size_2 button_theme button_js"
                                                    to="/booking/ireland-location">
                                                    <span className="button_label" style={{ minWidth: "100px" }}>
                                                        IRELAND
                                                    </span>
                                                </Link>
                                                <Link className="button button_size_2 button_theme button_js"
                                                    to="/booking/uk-location">
                                                    <span className="button_label" style={{ minWidth: "100px" }}>
                                                        NEWRY
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </BlankLayout>
    );
}

export default BookNowPage;