import React from 'react';
import DefaultLayout from '../../layout/DefautlLayout';
import Header from './../../components/header/Header';
import MainSliderHomePage from './../../components/mainSlider/MainSliderHomePage';
import { Link } from 'react-router-dom';

const LandingPage = () => {
    return (
        <DefaultLayout>
            <Header pagename="home">
                <MainSliderHomePage />
            </Header>

            <div id="Content">
                <div className="content_wrapper clearfix">
                    <div className="sections_group">
                        <div className="entry-content">
                            <div className="section mcb-section mcb-section-z569pxvnx"
                                style={{
                                    paddingTop: "110px",
                                    paddingBottom: "110px"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-e98u93utt one-third valign-top clearfix"
                                        style={{ padding: "0 3%" }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-tdm7aj5sa one column_image">
                                                <div
                                                    className="image_frame image_item scale-with-grid aligncenter no_border hover-disable">
                                                    <div className="image_wrapper">
                                                        {/* <a href="#"> */}
                                                        <div className="mask"></div><img className="scale-with-grid"
                                                            src="/assets/images/icons/0-doctor.png" alt="" />
                                                        {/* </a> */}
                                                        {/* <div className="image_links ">
                                                            <a href="#" className="link"><i className="icon-link"></i></a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column mcb-item-ua6kvic9s one column_column">
                                                <div className="column_attr clearfix align_center" >
                                                    <h3>
                                                        Doctor-Led <br />
                                                        Practice
                                                    </h3>
                                                    <p>
                                                        All of our treatments are carried out by medical professionals.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap mcb-wrap mcb-wrap-km0sn4j6n one-third valign-top clearfix"
                                        style={{ padding: "0 3%" }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-yg89bwogw one column_image">
                                                <div
                                                    className="image_frame image_item scale-with-grid aligncenter no_border hover-disable">
                                                    <div className="image_wrapper">
                                                        {/* <a href="#"> */}
                                                        <div className="mask"></div><img className="scale-with-grid"
                                                            src="/assets/images/icons/00-stars.png" alt="" />
                                                        {/* </a> */}
                                                        {/* <div className="image_links ">
                                                            <a href="#" className="link"><i className="icon-link"></i></a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column mcb-item-aa77feapk one column_column">
                                                <div className="column_attr clearfix align_center" >
                                                    <h3>
                                                        100 + <br />
                                                        5 Star Reviews
                                                    </h3>
                                                    <p>
                                                        Thousands of treatments carried out. You can see what our clients say on our reviews page, google & facebook.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap mcb-wrap mcb-wrap-75qcr7anb one-third valign-top clearfix"
                                        style={{ padding: "0 3%" }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-igwfothnd one column_image">
                                                <div
                                                    className="image_frame image_item scale-with-grid aligncenter no_border hover-disable">
                                                    <div className="image_wrapper">
                                                        {/* <a href="#"> */}
                                                        <div className="mask"></div><img className="scale-with-grid"
                                                            src="/assets/images/icons/0-clinic.png" alt="" />
                                                        {/* </a> */}
                                                        {/* <div className="image_links ">
                                                            <a href="#" className="link"><i className="icon-link"></i></a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column mcb-item-626rf5lk0 one column_column">
                                                <div className="column_attr clearfix align_center" >
                                                    <h3>
                                                        6 Clinics<br />
                                                        Nationwide
                                                    </h3>
                                                    <p>
                                                        See which clinic is nearest to you and book in for an appointment.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section mcb-section mcb-section-wjiyl5j4w">
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-o0zzfcfv1 one-second valign-top clearfix"
                                        style={{ padding: "0 4% 0 0" }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-eu8cujtqs one column_column">
                                                <div className="column_attr clearfix" >
                                                    <h6 className="insurance3-heading">ABOUT US</h6>
                                                    <hr className="no_line" style={{ margin: "0 auto 5px" }} />
                                                    <h2>
                                                        Why choose Sitara Medical Clinic?
                                                    </h2>
                                                    <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                    <p>
                                                        Our experienced team of medical professionals is all hand-picked through our rigorous process to deliver you the best results.
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="column mcb-column mcb-item-5c1gcv2zi one column_column column-margin-10px">
                                                <div className="column_attr clearfix"
                                                    style={{
                                                        backgroundImage: "url('./assets/images/insurance3-list.png')",
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'left top',
                                                        padding: "0 0 0 45px"
                                                    }}>
                                                    <p>
                                                        Professional Medical Clinic
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="column mcb-column mcb-item-01gj0ld84 one column_column column-margin-10px">
                                                <div className="column_attr clearfix mobile_align_center"
                                                    style={{
                                                        backgroundImage: "url('./assets/images/insurance3-list.png')",
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'left top',
                                                        padding: "0 0 0 45px"
                                                    }}>
                                                    <p>
                                                        Modern Clinic Facilities
                                                    </p>
                                                </div>
                                            </div>


                                            <div
                                                className="column mcb-column mcb-item-01gj0ld84 one column_column column-margin-10px">
                                                <div className="column_attr clearfix mobile_align_center"
                                                    style={{
                                                        backgroundImage: "url('./assets/images/insurance3-list.png')",
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'left top',
                                                        padding: "0 0 0 45px"
                                                    }}>
                                                    <p>
                                                        Thousands of Treatments Performed
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="column mcb-column mcb-item-xer4vbg70 one column_column">
                                                <div className="column_attr clearfix"
                                                    style={{
                                                        backgroundImage: "url('./assets/images/insurance3-list.png')",
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'left top',
                                                        padding: "0 0 0 45px"
                                                    }}>
                                                    <p>
                                                        Safe & Approved Treatments
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="column mcb-column mcb-item-7mxcewd8r one column_button" style={{ paddingTop: "20px", paddingBottom: "30px" }}>
                                                <Link to="/book-now" className="button button_size_2 button_theme_dark button_js">
                                                    <span className="button_label">
                                                        BOOK AN APPOINTMENT
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap mcb-wrap mcb-wrap-8g8p4dxos one-second valign-top move-up clearfix"
                                        style={{ marginTop: "-150px" }} data-mobile="no-up">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-vmoe0detp one column_image">
                                                <div className="image_frame image_item no_link scale-with-grid no_border">
                                                    <div className="image_wrapper"><img className="scale-with-grid rounded"
                                                        src="./assets/images/home/pic1-1.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section mcb-section mcb-section-x4mnvmffb"
                                style={{
                                    paddingTop: "110px",
                                    paddingBottom: "80px"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-c7wz6bc4u two-third valign-top clearfix">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-45zl19vod one column_column">
                                                <div className="column_attr clearfix" >
                                                    <h2>Services</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap mcb-wrap mcb-wrap-0a68ujf6p one-third valign-top clearfix"
                                        style={{ padding: "10px 0 0" }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-lykfyn4yj one column_button">
                                                <div className="button_align align_right">
                                                    <a className="button button_size_2 button_theme button_js"
                                                        href="/book-now">
                                                        <span className="button_label">
                                                            BOOK AN APPOINTMENT
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="wrap mcb-wrap mcb-wrap-e98u93utt one-second valign-top clearfix" style={{ padding: "0 0.5% 0 0" }}>
                                            <div className="icon_box icon_position_left no_border">
                                                <div className="icon_wrapper">
                                                    <img src="./assets/images/icons/anti-wrinkle-injection.png" className="scale-with-grid" alt="Step 1" />
                                                </div>
                                                <div className="desc_wrapper">
                                                    <h4>
                                                        Anti-Wrinkle Injections & Dermal Fillers
                                                    </h4>
                                                    <div className="desc">
                                                        Our team of medical Doctors is deft in safely delivering anti-wrinkle injections and dermal fillers. Their deep understanding of facial aesthetics in both women and men means they produce a look that is more youthful yet natural.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wrap mcb-wrap mcb-wrap-e98u93utt one-second valign-top clearfix" style={{ padding: "0 0.5% 0 0" }}>
                                            <div className="icon_box icon_position_left no_border">
                                                <div className="icon_wrapper">
                                                    <img src="./assets/images/icons/prp.png" className="scale-with-grid" alt="Step 1" />
                                                </div>
                                                <div className="desc_wrapper">
                                                    <h4>
                                                        PRP
                                                    </h4>
                                                    <div className="desc">
                                                        PRP promotes collagen synthesis and increases tissue blood flow, both of which are crucial in anti-aging treatments. As new collagen cells are activated and formed, the skin's texture and tone will increase. It takes 3-6 weeks for visible change to appear, and it can last up to 6 months. It's a good and natural solution to dermal fillers and other facial rejuvenation procedures.
                                                        </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="wrap mcb-wrap mcb-wrap-e98u93utt one-second valign-top clearfix" style={{ padding: "0 0.5% 0 0" }}>

                                            <div className="icon_box icon_position_left no_border">
                                                <div className="icon_wrapper">
                                                    <img src="./assets/images/icons/skin-booster.png" className="scale-with-grid" alt="Step 1" />
                                                </div>

                                                <div className="desc_wrapper">
                                                    <h4>
                                                        SkinBooster
                                                        </h4>

                                                    <div className="desc">
                                                        The Skinbooster is a relatively new skin rejuvenation treatment which is being requested by an increasing number of our clients. It is a treatment which uses micro-injections of a very soft hyaluronic acid to improve skin hydration, firmness and luminosity (glow). Skinboosters differ from regular fillers as they are extremely soft and work via biochemical properties. They reverse the skin aging process by reintroducing hyaluronic acid, a naturally occurring sugar molecule which gives skin plumpness and hydration.
                                                        </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="wrap mcb-wrap mcb-wrap-e98u93utt one-second valign-top clearfix" style={{ padding: "0 0.5% 0 0" }}>
                                            <div className="icon_box icon_position_left no_border">
                                                <div className="icon_wrapper">
                                                    <img src="./assets/images/icons/thread-lifting.png" className="scale-with-grid" alt="Step 1" />
                                                </div>
                                                <div className="desc_wrapper">
                                                    <h4>
                                                        Threadlifting
                                                        </h4>

                                                    <div className="desc">
                                                        Thread lift procedure is a minimally invasive alternative to facelift surgery. Thread lifts claim to tighten your skin by inserting medical-grade thread material into your face and then “pulling” your skin up by tightening the thread.
                                                        </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section center bg-theme" style={{ paddingTop: "30px", paddingBottom: "15px" }}>
                                <div className="section_wrapper clearfix">
                                    <div className="items_group clearfix">
                                        <div className="column_attr">
                                            <h2 className="text-light styled-font" style={{ letterSpacing: "3px" }}>
                                                Helping you feel Beautiful & Confident since 2016
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section mcb-section home_services_section"
                                style={{
                                    paddingTop: "110px",
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="column mcb-column one column_column">
                                        <div className="column_attr clearfix">
                                            <h2>The Process</h2>
                                        </div>
                                    </div>
                                    <div className="section mcb-section equal-height full-width">
                                        <div className="section_wrapper mcb-section-inner">
                                            <div className="wrap mcb-wrap one valign-top clearfix">
                                                <div className="mcb-wrap-inner">

                                                    <div className="column mcb-column one-third column_column column_how_it_works">
                                                        <div className="column_attr how_it_works" style={{ margin: "0 5px 10px 5px" }}>
                                                            <div className="image">
                                                                <img src="./assets/images/icons/consultation.png" className="scale-with-grid" alt="Step 1" />
                                                                <span className="number">1</span>
                                                            </div>
                                                            <h3>Consultation</h3>
                                                            <div className="desc">
                                                                All of our procedures begin with a private consultation that involves a thorough facial examination by our trained professionals. The client's areas of concern are addressed, the possible options are explored and a individualised treatment proposal is
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="column mcb-column one-third column_column column_how_it_works">
                                                        <div className="column_attr how_it_works" style={{ margin: "0 5px 10px 5px" }}>
                                                            <div className="image">
                                                                <img src="./assets/images/icons/treatment.png" className="scale-with-grid" alt="Step 2" />
                                                                <span className="number">2</span>
                                                            </div>
                                                            <h3>Treatment</h3>
                                                            <div className="desc">
                                                                After the consultation, is the treatment phase. This only takes a matter of minutes and the utmost care is taken to ensure a high quality result for the client.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="column mcb-column one-third column_column column_how_it_works">
                                                        <div className="column_attr how_it_works" style={{ margin: "0 5px 10px 5px" }}>
                                                            <div className="image">
                                                                <img src="./assets/images/icons/after-care.png" className="scale-with-grid" alt="Step 3" />
                                                                <span className="number">3</span>
                                                            </div>
                                                            <h3>After-care</h3>
                                                            <div className="desc">
                                                                After the treatment is complete, we provide our clients with detailed after care advice. There is also an optional follow up appointment available two weeks after the initial treatment.
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            {/* Reviews section */}
                            <div className="section mcb-section home_services_section"
                                style={{
                                    paddingTop: "110px",
                                    paddingBottom: "80px"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="column mcb-column one column_column">
                                        <div className="column_attr clearfix">
                                            <h2>What People Say</h2>
                                        </div>
                                    </div>

                                    <div className="wrap mcb-wrap one valign-top clearfix">
                                        <div className="mcb-wrap-inner" style={{ display: "flex", flexWrap: "wrap" }}>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/jayde.obrien.1/posts/4254313387947091" data-width="" data-show-text="true">
                                                        <blockquote cite="https://www.facebook.com/jayde.obrien.1/posts/4254313387947091" className="fb-xfbml-parse-ignore">
                                                            <p>Got my lips done today by Nurse Lauren 😄 the care in the clinic was 10/10 she took her time and didn’t rush me at all....</p>
                                                                    Posted by <a href="https://www.facebook.com/jayde.obrien.1">Jayde O&#039;Brien</a> on&nbsp;
                                                                    <a href="https://www.facebook.com/jayde.obrien.1/posts/4254313387947091">Monday, 19 April 2021</a>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/Deemac38/posts/10221646686929805" data-width="" data-show-text="true">
                                                        <blockquote cite="https://www.facebook.com/Deemac38/posts/10221646686929805" className="fb-xfbml-parse-ignore"><p>I love visiting Dr Sana for my treatments. I have been attending 2.5 years now and I would not go to anyone else. The...</p>Posted by <a href="#" role="button">Deirdre Mc Carthy</a> on&nbsp;<a href="https://www.facebook.com/Deemac38/posts/10221646686929805">Monday, 13 July 2020</a></blockquote>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/permalink.php?story_fbid=2042776622680679&amp;id=100008450832404" data-width="" data-show-text="true">
                                                        <blockquote cite="https://www.facebook.com/permalink.php?story_fbid=2042776622680679&amp;id=100008450832404" className="fb-xfbml-parse-ignore"><p>Dr. Sana is a perfectionist, I visited her clinic at the end of October and talked me through every little detail before...</p>Posted by <a href="https://www.facebook.com/people/Stacey-Brien/100008450832404/">Stacey Brien</a> on&nbsp;<a href="https://www.facebook.com/permalink.php?story_fbid=2042776622680679&amp;id=100008450832404">Saturday, 29 December 2018</a></blockquote>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/sharonjit.virk/posts/10158636594034496" data-width="" data-show-text="true">
                                                        <blockquote cite="https://www.facebook.com/sharonjit.virk/posts/10158636594034496" className="fb-xfbml-parse-ignore"><p>Sana is an amazing clinician who is so talented! I love how she is so skilled at what she does.  She is patient and calm...</p>Posted by <a href="#" role="button">Sharonjit Kaur Virk</a> on&nbsp;<a href="https://www.facebook.com/sharonjit.virk/posts/10158636594034496">Sunday, 1 October 2017</a></blockquote>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one column_column">
                                                <div className="column_attr align_center">
                                                    <div className="button_align align_right">
                                                        <a className="button button_size_2 button_theme button_js"
                                                            href="/reviews">
                                                            <span className="button_label">
                                                                SEE MORE REVIEWS
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}

export default LandingPage;
