import React from 'react';
import DefaultLayout from '../../../layout/DefautlLayout';
import Header from '../../../components/header/Header';
import styled from './style.module.css';

import { Link } from 'react-router-dom';

const PdoThreadsPage = () => {
	return (
		<DefaultLayout>
			<Header />
			<div id="Content">
				<div className="content_wrapper clearfix">
					<div className="sections_group">
						<div className="extra_content">
							<div
								className={`section mcb-section mcb-section-1186132e1 bg-cover`}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
									backgroundImage: 'url(/assets/images/banners/treatment-xl-2.png)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'right bottom',
								}}>
								<div className="section_wrapper mcb-section-inner">
									<div
										className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
										style={{
											padding: '30% 0 10%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
												<div className={`clearfix ${styled.bannerTextWidth}`}>
													<h2>PDO Threads</h2>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Polydioxanone (PDO) threads are special “suture-like” threads, made of protein. When this thread is
														placed in the skin, it begins a process to produce collagen. Collagen is what we naturally lose in our
														skin, which is why our skin loses elasticity.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className={styled.pusherDiv}></div>

							<div className="section center bg-theme hrmargin_b_60" style={{ paddingTop: '30px', paddingBottom: '15px' }}>
								<div className="section_wrapper clearfix">
									<div className="items_group clearfix">
										<div className="column_attr">
											<h3 className="text-light" style={{ letterSpacing: '3px' }}>
												" Beauty is self confidence applied directly to the face "
											</h3>
										</div>
									</div>
								</div>
							</div>

							<div
								className="section mcb-section"
								id="image"
								style={{
									paddingBottom: '70px',
								}}>
								<div className="section_wrapper mcb-section-inner">
									<div className="wrap mcb-wrap one valign-top clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<p>
														There are two types of PDO threads - smooth mono-threads, or barbed threads. The smooth threads work to
														rejuvenate the skin and also has a lipolytic quality (destroys fat cells). This is ideal for areas such
														as the jawline, under the chin, and smile lines. The barbed threads have a pulling quality, which can
														lift loose skin for some time. This is often used to lift the brows, the cheeks or the jawline/jowl.
														Both threads dissolve naturally and leave a layer of collagen behind in the skin.
													</p>
												</div>
											</div>

											{/* <div className="column mcb-column one-third column_image">
                                                <div className="image_frame image_item scale-with-grid aligncenter has_border">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" src="/assets/images/courses/image-1.jpg" alt="home_betheme_blog_masonry_1" width="960" height="960" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column two-third column_column">
                                                <div className="column_attr clearfix">
                                                    <h4>
                                                        WHAT ARE ANTI-WRINKLE INJECTIONS?
                                                    </h4>
                                                    <p>
                                                        
                                                    </p>
                                                    <h4>
                                                        Why choose Sitara Clinic for Anti-Wrinkle injections?
                                                    </h4>
                                                    <ul>
                                                        <li>
                                                            
                                                            
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div> */}
											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<div className="column one column_pricing_item">
														<div className="pricing-box custom-pricing-box pricing-box-table pricing-box-featured">
															<div className="plan-header">
																<h3>Pricing</h3>
															</div>

															<div className="plan-inside clearfix">
																<hr className="bg-theme" />
																<ul>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div style={{ textAlign: 'center' }}>
																			<strong>Price on consultation</strong>
																		</div>
																	</li>
																</ul>
																<hr className="bg-theme" />
															</div>

															<div className="plan-footer">
																<Link to="/book-now" className="button  button_left button_theme button_js">
																	<span className="button_icon">
																		<i className="icon-basket"></i>
																	</span>
																	<span className="button_label">BOOK NOW</span>
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column one column_slider">
												<div className="column_attr clearfix hrmargin_b_60">
													<h2
														className="hrmargin_b_60"
														style={{
															textAlign: 'center',
														}}>
														Results
													</h2>

													<div className="content_slider">
														<ul className="content_slider_ul">
															<li className="content_slider_li_1">
																<img
																	width="100%"
																	height="auto"
																	src="/assets/images/treatments/slider/pdo-threads-1.jpg"
																	alt="PDO Threads"
																	className="scale-with-grid wp-post-image"
																/>
															</li>
														</ul>
													</div>
												</div>
											</div>

											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<h2
														className="hrmargin_b_60"
														style={{
															textAlign: 'center',
														}}>
														After-Care
													</h2>
													<p>Bruising and swelling is normal and to be expected. Arnica cream can be applied to manage bruising.</p>
													<p>
														<strong>AVOID:</strong>
													</p>
													<ul>
														<li>Excessive movement or animation of the area injected for 2 weeks</li>
														<li>Massage or manipulation of the tissue for 2 weeks.</li>
														<li>Aesthetic treatments including RF, IPL, Laser, or Microneedling for 4 weeks.</li>
														<li>Strenuous exercise for 1 week after facial treatments and 2 weeks afterbody treatments.</li>
														<li>
															Steam rooms, saunas, hot tubs, or any water source that could be unclean or excessive in temperature
															for two weeks.
														</li>
														<li>Taking Ibuprofen for 2 weeks. Paracetamol may be taken instead, if needed</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default PdoThreadsPage;
