import axios from 'axios';

export const contactUs = (data) => {
	const { email, name, phoneNo, message } = data;
	return new Promise((resolve, reject) => {
		const data = new FormData();
		data.append('requirements', '--NA--');
		data.append('where', '--NA--');
		data.append('company', '--NA--');
		data.append('recaptcha', '00000');
		data.append('email', email);
		data.append('name', name);
		data.append('phoneNo', phoneNo);
		data.append('message', message);

		axios({
			method: 'POST',
			baseURL: `https://api-cloud.devcentrehouse.eu`,
			url: `contact/contact.php/send-email-to-sitara-clinic`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			responseType: 'json',
			data,
		})
			.then((result) => resolve(result.data))
			.catch((error) => reject(error.response));
	});
};
