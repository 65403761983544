import React from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {
    const { children } = props;
    return (
        <div id="Header_wrapper">
            <header id="Header">
                <div id="Top_bar">
                    <div className="container custom-width" >
                        <div className="column one">
                            <div className="top_bar_left clearfix">
                                <div className="logo">
                                    <a href="/" id="logo" data-padding="30">
                                        <img
                                            className="logo-main scale-with-grid"
                                            src="/assets/images/logo/sitara-clinic.png"
                                            data-retina="/assets/images/logo/retina-sitara-clinic.png"
                                            data-no-retina alt="" />
                                        <img
                                            className="logo-sticky scale-with-grid"
                                            src="/assets/images/logo/sitara-clinic.png"
                                            data-retina="/assets/images/logo/retina-sitara-clinic.png"
                                            data-no-retina alt="" />
                                        <img
                                            className="logo-mobile scale-with-grid"
                                            src="/assets/images/logo/sitara-clinic.png"
                                            data-retina="/assets/images/logo/retina-sitara-clinic.png"
                                            data-no-retina alt="" />
                                        <img
                                            className="logo-mobile-sticky scale-with-grid"
                                            src="/assets/images/logo/sitara-clinic.png"
                                            data-retina="/assets/images/logo/retina-sitara-clinic.png"
                                            data-no-retina alt="" />
                                    </a>
                                </div>
                                <div className="menu_wrapper">
                                    <nav id="menu">
                                        <ul id="menu-main-menu" className="menu menu-main">
                                            <li>{/*  className="current-menu-item" */}
                                                <Link to="/"><span>HOME</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/about"><span>ABOUT</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/treatments"><span>TREATMENTS</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/courses"><span>COURSES</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/reviews"><span>REVIEWS</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/voucher"><span>VOUCHER</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/contact-us"><span>CONTACT US</span></Link>
                                            </li>
                                            <li className="d-xl-none">
                                                <Link to="/book-now"><span>BOOK NOW</span></Link>
                                            </li>
                                        </ul>
                                    </nav><a className="responsive-menu-toggle" href="#"><i className="icon-menu-fine"></i></a>
                                </div>
                            </div>
                            <div className="top_bar_right">
                                <div className="top_bar_right_wrapper">
                                    <Link to="/book-now" className="action_button">
                                        BOOK NOW <i className="icon-right-open"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {children}


            </header>
        </div>
    );
}

export default Header;
