import React from 'react';
import DefaultLayout from '../../layout/DefautlLayout';
import Header from './../../components/header/Header';
import styled from './aboutPage.module.css';

const AboutPage = () => {
	return (
		<DefaultLayout>
			<Header />

			<div id="Content">
				<div className="content_wrapper clearfix">
					<div className="sections_group">
						<div className="extra_content">
							<div
								className="section mcb-section mcb-section-1186132e1 bg-cover"
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
									backgroundImage: 'url(/assets/images/banners/about-us-xl-2.png)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'right bottom',
								}}>
								<div className="section_wrapper mcb-section-inner">
									<div
										className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
										style={{
											padding: '30% 0 10%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
												<div className={`clearfix ${styled.bannerTextWidth}`}>
													<h2>About Us</h2>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														<strong>
															Sitara Medical Clinic is committed to assisting our patients in achieving their beauty & aesthetic
															goals.
														</strong>{' '}
														Sitara Clinic's goal is to be the luxurious cosmetic clinic of choice for beauty. We don't focus on
														genetics. To achieve the high-end, flawless look that leads our patients to the life of their dreams, we
														battle any flaw that is possible.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className={styled.pusherDiv}></div>

							<div className="section center bg-theme" style={{ paddingTop: '30px', paddingBottom: '15px' }}>
								<div className="section_wrapper clearfix">
									<div className="items_group clearfix">
										<div className="column_attr">
											<h3 className="text-light" style={{ letterSpacing: '3px', marginBottom: 0 }}>
												Sitara Medical Clinic
											</h3>
											<h4 className="text-light">#1 Cosmetic Clinic in Ireland</h4>
										</div>
									</div>
								</div>
							</div>

							<div
								className="section mcb-section mcb-section-lsgo36z5s"
								style={{
									paddingTop: '110px',
									paddingBottom: '70px',
									// backgroundImage: "url(./assets/images/insurance3-sectionbg3.png)",
									backgroundRepeat: 'repeat-x',
									backgroundPosition: 'center top',
								}}>
								<div className="section_wrapper mcb-section-inner hrmargin_b_30">
									<div className="wrap mcb-wrap mcb-wrap-lr1bo58h7 one-third valign-middle clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-69sgk7sg1 one column_image">
												<div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
													<div className="image_wrapper">
														<img className="scale-with-grid" src="/assets/images/about/sana_2.png" alt="Dr. Sana Askary pic" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="wrap mcb-wrap mcb-wrap-1mhmri34p two-third valign-middle clearfix"
										style={{
											padding: '0 0 0 5%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-2ph3gb2bh one column_column column-margin-30px">
												<div className="column_attr clearfix">
													<h3>Dr. Sana Askary</h3>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 5px',
														}}
													/>
													<h6 className="insurance3-heading bg-theme">BDS (Hons) NUI, Dip PCD (RCSI)</h6>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Dr Sana graduated as a dentist from University College Cork and trained in facial aesthetics in the UK.
														She is the founder of Sitara Medical Clinic. Her passion in facial aesthetics stems from her advanced
														knowledge of head and neck anatomy. She is Ireland's trainer for world renowned brand Galderma which
														manufactures Azzalure toxin and Restylane filler. She has trained with famous clinicians such as Prof.
														Bob Khanna and Dr. Arther Swift. She is an active member of the Aesthetics Complications Expert group,
														and has taken a leading role in management of aesthetic complications in Ireland. Sana's approach
														involves taking time to listen to her clients for a better understanding of their treatment goals and
														addressing their concerns. She aims for natural-looking results to help her clients feel beautiful and
														confident. She strives to create the best and safest techniques, always keeping up with the most recent
														medical research and guidelines.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="section_wrapper mcb-section-inner hrmargin_b_30 d-md-flex row-reverse">
									<div className="wrap mcb-wrap mcb-wrap-lr1bo58h7 one-third valign-middle clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-69sgk7sg1 one column_image">
												<div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
													<div className="image_wrapper">
														<img className="scale-with-grid" src="/assets/images/about/bianca.png" alt="Dr. Bianca Bandarra pic" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="wrap mcb-wrap mcb-wrap-1mhmri34p two-third valign-middle clearfix"
										style={{
											padding: '0 0 0 5%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-2ph3gb2bh one column_column column-margin-30px">
												<div className="column_attr clearfix">
													<h3>Dr. Bianca Bandarra</h3>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 5px',
														}}
													/>
													<h6 className="insurance3-heading bg-theme">MD</h6>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Dr. Bianca is one of our prized possessions at Sitara Medical Clinic. Her background is in General
														Surgery, and she is currently completing her GP training. These two aspects of her medical training give
														her both the dexterity and empathy skills that make an excellent aesthetic doctor. Having trained
														locally in Ireland, and internationally in London and Brazil, Dr. Bianca really brings a high level of
														innovation to her practice. Aesthetics is very important to her as she believes we all have an inner
														beauty that can be enhanced by some touch-ups without changing who we are.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="section_wrapper mcb-section-inner hrmargin_b_30">
									<div className="wrap mcb-wrap mcb-wrap-lr1bo58h7 one-third valign-middle clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-69sgk7sg1 one column_image">
												<div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
													<div className="image_wrapper">
														<img
															className="scale-with-grid"
															src="/assets/images/about/tara_hendley.png"
															alt="Dr. Tara Hendley pic"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="wrap mcb-wrap mcb-wrap-1mhmri34p two-third valign-middle clearfix"
										style={{
											padding: '0 0 0 5%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-2ph3gb2bh one column_column column-margin-30px">
												<div className="column_attr clearfix">
													<h3>Dr. Tara Hendley</h3>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 5px',
														}}
													/>
													<h6 className="insurance3-heading bg-theme">LRCP & SI MB BCH NUI, DOWH</h6>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Dr. Tara Hendley qualified as a doctor from the Royal College of Surgeons in Ireland with an honours
														degree in Medicine in 2009. She went on to further train as a general practitioner. She began working in
														aesthetics and injectables in 2017 and since then, divides her time between working as a GP and working
														as an aesthetic practitioner. She has completed a postgraduate/ Level 7 diploma in Aesthetic Medicine
														through Harley Academy London in 2021. SHe has completed a postgraduate certificate in Dermatology
														through UCD in 2022. Her extensive background in general medicine gives her a broad foundation to
														accurately treat and recognise skin changes and facial aging concerns for her clients. Her years of GP
														experience ensure Dr. Tara approaches her work with empathy, honesty, professionalism and knowledge. She
														strives to work with patients to create natural and fresh looks that beautify and rejuvenate.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="section_wrapper mcb-section-inner hrmargin_b_30 d-md-flex row-reverse">
									<div className="wrap mcb-wrap mcb-wrap-lr1bo58h7 one-third valign-middle clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-69sgk7sg1 one column_image">
												<div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
													<div className="image_wrapper">
														<img
															className="scale-with-grid"
															src="/assets/images/about/ciara_maharaj.png"
															alt="Dr Ciara Mahraj pic"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="wrap mcb-wrap mcb-wrap-1mhmri34p two-third valign-middle clearfix"
										style={{
											padding: '0 0 0 5%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-2ph3gb2bh one column_column column-margin-30px">
												<div className="column_attr clearfix">
													<h3>Dr Ciara Mahraj</h3>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 5px',
														}}
													/>
													{/* <h6 className="insurance3-heading bg-theme"></h6> */}
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Dr. Ciára graduated with an honours degree in dentistry from University College Cork in 2011. While
														working in the UK in 2012, she began to attend facial aesthetics training courses, and has been a
														devoted practitioner ever since. This led to her decision to pursue the MSc in Aesthetic Medicine. Ciára
														is a member of The American Association of Aesthetic Medicine and Surgery (AAAMS) and regularly updates
														her knowledge by training with clinicians who are at the forefront of the aesthetic industry, such as Dr
														Tim Pearce, Dr. Kate Goldie, and our in house expert Dr. Sana Askary. Ciára's cohesive approach to the
														facial structures ensures harmonious and natural looking results. The confidence that looking good gives
														to her patients is her favourite thing about working in medical aesthetics.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="section_wrapper mcb-section-inner hrmargin_b_30">
									<div className="wrap mcb-wrap mcb-wrap-lr1bo58h7 one-third valign-middle clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-69sgk7sg1 one column_image">
												<div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
													<div className="image_wrapper">
														<img className="scale-with-grid" src="/assets/images/about/ariba_karim.png" alt="Dr Ariba Karim pic" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="wrap mcb-wrap mcb-wrap-1mhmri34p two-third valign-middle clearfix"
										style={{
											padding: '0 0 0 5%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-2ph3gb2bh one column_column column-margin-30px">
												<div className="column_attr clearfix">
													<h3>Dr Ariba Karim</h3>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 5px',
														}}
													/>
													{/* <h6 className="insurance3-heading bg-theme"></h6> */}
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Ariba graduated from queens university belfast and trained with the royal college of surgeons after in
														oral surgery. She loves cooking, fitness and travelling. Ariba likes to combine a holistic approach to
														her aesthetic treatments with patients.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="section_wrapper mcb-section-inner hrmargin_b_30 d-md-flex row-reverse">
									<div className="wrap mcb-wrap mcb-wrap-lr1bo58h7 one-third valign-middle clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-69sgk7sg1 one column_image">
												<div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
													<div className="image_wrapper">
														<img
															className="scale-with-grid"
															src="/assets/images/about/sujitha_ramesh.png"
															alt="Dr Sujitha Ramesh pic"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="wrap mcb-wrap mcb-wrap-1mhmri34p two-third valign-middle clearfix"
										style={{
											padding: '0 0 0 5%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-2ph3gb2bh one column_column column-margin-30px">
												<div className="column_attr clearfix">
													<h3>Dr Sujitha Ramesh</h3>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 5px',
														}}
													/>
													<h6 className="insurance3-heading bg-theme">BA. B.Dent.Sc, Dip PCD, MFD (RCSI)</h6>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Dr. Sujitha graduated from the prestigious Trinity College Dublin in 2017 as a dental surgeon. Her
														career in facial aesthetics commenced shortly afterwards when she completed extensive training in
														advanced botulinum toxin and dermal filler in the London School of Facial Aesthetics.
													</p>
													<p>
														Dr. Sujitha prides herself in a holistic approach by having a thorough consultation with each of her
														clients prior to treatment which allows her to identity areas of concerns and treatment goals unique to
														every client. This enables her to boost the confidence of her clients by enhancing their natural beauty.
														Her gentle manner makes her very popular among our nervous patients. She undergoes cosmetic training on
														a regular basis to keep up to date with the latest techniques and research in aesthetic medicine. In her
														spare time, Dr. Sujitha enjoys travelling, singing and cooking.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="section_wrapper mcb-section-inner hrmargin_b_30">
									<div className="wrap mcb-wrap mcb-wrap-lr1bo58h7 one-third valign-middle clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-69sgk7sg1 one column_image">
												<div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
													<div className="image_wrapper">
														<img
															className="scale-with-grid"
															src="/assets/images/about/lauren.png"
															alt="Nurse Injector Lauren Paton pic"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div
										className="wrap mcb-wrap mcb-wrap-1mhmri34p two-third valign-middle clearfix"
										style={{
											padding: '0 0 0 5%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-2ph3gb2bh one column_column column-margin-30px">
												<div className="column_attr clearfix">
													<h3>Nurse Injector Lauren Paton</h3>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 5px',
														}}
													/>
													<h6 className="insurance3-heading bg-theme">RGN, B.NSc</h6>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Nurse Lauren's career began as a dedicated acute stroke nurse with more than 5 years in one of London's
														top hospitals. She trained in Kingston University, London and graduated with a Bachelor's of Science
														degree in Nursing. Lauren's interest in facial aesthetics came during this time as she trained in dermal
														and lip filler in London's famous medical district Harley Street with Dr. Kate Goldie. She has also
														clocked in several hours of training with Dr. Sana. Lauren has since expanded her skills to train in PRP
														(also known as the vampire facial) which is a more natural approach to skin rejuvenation and hair loss.
														She has an in-depth knowledge of the ZO medical skincare range. Lauren loves to chat to clients about
														all things skin and treatments available to them. Her aim is to achieve natural and glowing results,
														which is completely in line with the Sitara Medical Clinic ethos.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="section section-post-about">
									<div className="section_wrapper clearfix">
										<div className="column one author-box">
											<div className="author-box-wrapper">
												<div className="avatar-wrapper">
													<img
														src="http://2.gravatar.com/avatar/b8da4787ca5ff7a6bb9bbabecce2daa2?s=64&amp;r=g"
														className="avatar avatar-64 photo"
														height="64"
														width="64"
														alt=""
													/>
												</div>
												<div className="desc-wrapper bg-theme">
													<h3 className="text-light">Want to join Sitara Clinic?</h3>
													<div className="desc text-light">
														We are always looking for enthusiastic people who are devoted to excellence to work with us. If this
														sounds like you, please send us an email.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default AboutPage;
