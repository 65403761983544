import React from 'react';
import DefaultLayout from '../../../layout/DefautlLayout';
import Header from '../../../components/header/Header';
import styled from './style.module.css';

import { Link } from 'react-router-dom';

const DissolvingFillerPage = () => {
	return (
		<DefaultLayout>
			<Header />
			<div id="Content">
				<div className="content_wrapper clearfix">
					<div className="sections_group">
						<div className="extra_content">
							<div
								className={`section mcb-section mcb-section-1186132e1 bg-cover`}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
									backgroundImage: 'url(/assets/images/banners/treatment-xl-2.png)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'right bottom',
								}}>
								<div className="section_wrapper mcb-section-inner">
									<div
										className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
										style={{
											padding: '30% 0 10%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
												<div className={`clearfix ${styled.bannerTextWidth}`}>
													<h2>Dissolving Filler</h2>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Hyaluronic filler can be dissolved using an enzyme called Hyaluronidase. Whether you feel like your
														filler has migrated from its original place, you have a persistent lump of filler or if you just don’t
														like/want filler anymore - this procedure can reverse its effects almost instantly.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className={styled.pusherDiv}></div>

							<div className="section center bg-theme hrmargin_b_60" style={{ paddingTop: '30px', paddingBottom: '15px' }}>
								<div className="section_wrapper clearfix">
									<div className="items_group clearfix">
										<div className="column_attr">
											<h3 className="text-light" style={{ letterSpacing: '3px' }}>
												" A woman’s greatest asset is her beauty. "
											</h3>
										</div>
									</div>
								</div>
							</div>

							<div
								className="section mcb-section"
								id="image"
								style={{
									paddingBottom: '70px',
								}}>
								<div className="section_wrapper mcb-section-inner">
									<div className="wrap mcb-wrap one valign-top clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<p>
														Filler can also be dissolved in case of an emergency. This has become particularly common with the rise
														in non-medical practitioners carrying out filler procedures. If you feel like you might be having a
														filler-related emergency, please contact us immediately.
													</p>
												</div>
											</div>

											{/* <div className="column mcb-column one-third column_image">
                                                <div className="image_frame image_item scale-with-grid aligncenter has_border">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" src="/assets/images/courses/image-1.jpg" alt="home_betheme_blog_masonry_1" width="960" height="960" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column two-third column_column">
                                                <div className="column_attr clearfix">
                                                    <h4>
                                                        WHAT ARE ANTI-WRINKLE INJECTIONS?
                                                    </h4>
                                                    <p>
                                                        
                                                    </p>
                                                    <h4>
                                                        Why choose Sitara Clinic for Anti-Wrinkle injections?
                                                    </h4>
                                                    <ul>
                                                        <li>
                                                           
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div> */}
											{/* <div className="column mcb-column one column_divider">
                                                <hr className="no_line" />
                                            </div> */}

											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<div className="column one column_pricing_item">
														<div className="pricing-box custom-pricing-box pricing-box-table pricing-box-featured">
															<div className="plan-header">
																<h3>Pricing</h3>
															</div>

															<div className="plan-inside clearfix">
																<hr className="bg-theme" />
																<ul>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Treatment cost</strong>
																		</div>
																		<div>
																			Dr Sana - €150 <br />
																			Injectors - €150
																		</div>
																	</li>
																</ul>
																<hr className="bg-theme" />
															</div>

															<div className="plan-footer">
																<Link to="/book-now" className="button  button_left button_theme button_js">
																	<span className="button_icon">
																		<i className="icon-basket"></i>
																	</span>
																	<span className="button_label">BOOK NOW</span>
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column one column_slider">
												<div className="column_attr clearfix hrmargin_b_60">
													<h2
														className="hrmargin_b_60"
														style={{
															textAlign: 'center',
														}}>
														Results
													</h2>

													<div className="content_slider">
														<ul className="content_slider_ul">
															<li className="content_slider_li_1">
																<img
																	width="100%"
																	height="auto"
																	src="/assets/images/treatments/slider/dissolving-filler-1.jpg"
																	alt="Dissolving Filler"
																	className="scale-with-grid wp-post-image"
																/>
															</li>
														</ul>
													</div>
												</div>
											</div>

											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<h2
														className="hrmargin_b_60"
														style={{
															textAlign: 'center',
														}}>
														After-Care
													</h2>
													<ul>
														<li>
															After treatment, swelling and bruising can be expected. Applying an ice-pack to the lips aids the
															swelling.
														</li>
														<li>Arnica cream can help to heal the bruising. Massage the area thoroughly for the first 24 hours.</li>
														<li>Avoid any extreme exercise for 24 hours.</li>
														<li>Generally it takes 24-48 hours for the filler to completely dissolve.</li>
														<li>You must wait 5 days before you can get filler again.</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default DissolvingFillerPage;
