import React from 'react';
import DefaultLayout from '../../layout/DefautlLayout';
import Header from './../../components/header/Header';
import styled from './coursesPage.module.css';

const CoursesPage = () => {
    return (
        <DefaultLayout>
            <Header />
            <div id="Content">
                <div className="content_wrapper clearfix">
                    <div className="sections_group">
                        <div className="extra_content">
                            {/* banner */}
                            <div className="section mcb-section mcb-section-1186132e1 bg-cover"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    backgroundImage: "url(/assets/images/banners/courses-xl-2.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "right bottom"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
                                        style={{
                                            padding: "30% 0 10%"
                                        }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
                                                <div className={`clearfix ${styled.bannerTextWidth}`}>
                                                    <h2>Courses</h2>
                                                    <hr className="no_line"
                                                        style={{
                                                            margin: "0 auto 15px"
                                                        }} />
                                                    <p>
                                                        We wanted to make the aesthetics teaching industry better. That’s why Sitara Medical Clinic, also offers training with a focus on creating high level injectors, many that come and work with us. If you are a medical practitioner interested in Aesthetic Medicine, please feel free to get in touch regarding our courses.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styled.pusherDiv}></div>

                            {/* pink strip */}
                            <div className="section center bg-theme hrmargin_b_60" style={{ paddingTop: "30px", paddingBottom: "15px" }}>
                                <div className="section_wrapper clearfix">
                                    <div className="items_group clearfix">
                                        <div className="column_attr">
                                            <h3 className="text-light" style={{ letterSpacing: "3px" }}>
                                                Facial Aesthetics Training with Dr. Sana Askary
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* main content */}
                            <div className="section mcb-section" id="image" style={{
                                paddingBottom: "70px"
                            }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap one valign-top clearfix">
                                        <div className="mcb-wrap-inner">

                                            <div className="column mcb-column one-third column_image">
                                                <div className="image_frame image_item scale-with-grid aligncenter has_border">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" src="/assets/images/courses/image01.png" alt="home_betheme_blog_masonry_1" width="960" height="960" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column two-third column_column">
                                                <div className="column_attr clearfix">
                                                    <h4>
                                                        Why Train at Sitara Medical Clinic?
                                                    </h4>
                                                    <ul>
                                                        <li>In-depth anatomy training</li>
                                                        <li>Small class group (maximum three per session)</li>
                                                        <li>Hands-on practical experience with close supervision and feedback</li>
                                                        <li>Get certified and insured to carry out treatment</li>
                                                        <li>Complications prevention and management covered</li>
                                                        <li>24/7 Mentoring and support provided after the training</li>
                                                        <li>1:1 training available upon request</li>
                                                        <li>Business mentoring</li>
                                                    </ul>

                                                </div>
                                            </div>
                                            <div className="column mcb-column one column_divider">
                                                <hr className="no_line" />
                                            </div>

                                            <div className="column mcb-column two-third column_column">
                                                <div className="column_attr clearfix">
                                                    <h4>
                                                        Basic Botulinum Toxin Training
                                                    </h4>

                                                    <p>
                                                        This course is perfect for doctors and dentists who are looking to start their journey in facial aesthetics. The areas covered in this course are the forehead, frown and crows-feet. This makes up about 90% of all facial aesthetic patients.
                                                    </p>

                                                    <h4>
                                                        Basic Dermal and Lip Filler Training
                                                    </h4>
                                                    <p>
                                                        Doctors, dentists and registered nurses can apply to train in dermal and lip filler. Areas covered are lips, nasolabial folds and marionette lines. Cannula technique taught for dermal fillers.
													</p>

                                                    <h4>
                                                        Advanced Botulinum Toxin Training
                                                    </h4>
                                                    <p>
                                                        For doctors and dentists who have already trained and treated patients in the beginner areas. This comprehensive course covers eye-brow lifting, bunny lines, smoker lines, depressor angular oris (corner of mouth), Nefertiti neck lift, hyperhydrosis treatment (excessive sweating), masseter muscle (teeth-grinding), gummy smile and lip flip.
													</p>
                                                    <h4>
                                                        Advanced Filler Training
                                                    </h4>
                                                    <p>
                                                        For doctors, dentists and registered nurses who have experience in dermal filler. Learn the artistry of filler through areas such as cheeks, chin, jawline and tear trough. Needle and cannula techniques will be taught here. Learn insider tips and tricks gathered by Dr. Sana through the years.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="column mcb-column one-third column_image if-overlay">
                                                <div className="image_frame image_item scale-with-grid aligncenter has_border">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" src="/assets/images/courses/image02.png" alt="home_betheme_blog_classic_1" width="1200" height="1000" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column one column_divider">
                                                <hr className="no_line" />
                                            </div>

                                            <div className="column mcb-column one-third column_image">
                                                <div className="image_frame image_item scale-with-grid aligncenter has_border">
                                                    <div className="image_wrapper">
                                                        <div className="mask"></div>
                                                        <img className="scale-with-grid" src="/assets/images/courses/image03.png" alt="home_betheme_blog_masonry_1" width="960" height="960" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column mcb-column two-third column_column">
                                                <div className="column_attr clearfix">
                                                    <h4>
                                                        Lips Masterclass
                                                    </h4>
                                                    <p>
                                                        This class covers special tips and techniques to get those Sitara Medical Clinic style lips. We will go through different types of needles, as well as cannula techniques. Suitable for doctors, dentists and nurses with experience in lip filler.
                                                    </p>
                                                    <h4>
                                                        Complications Class
                                                    </h4>
                                                    <p>
                                                        Still anxious about carrying out procedures? Whether it is a ptosis caused by botulinum toxin, delayed-onset nodules or the ever-dreaded vascular occlusion, we can give you the knowledge you need to identify and manage a complication.
                                                    </p>
                                                    <h4>
                                                        Shadowing Day
                                                    </h4>
                                                    <p>
                                                        Want to see what a day in the life of a busy aesthetic doctor is like? You can shadow Dr. Sana for the day to see a variety of treatments. If there is any treatment in particular you would like to see in the day, you can request it in advance.
                                                    </p>
                                                    <h4>
                                                        Colleague Support and Advice
                                                    </h4>
                                                    <p>
                                                        Dr. Sana is available to give clinical support during a complication. In the case of an emergency, please contact directly at 0879945808.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* FAQS */}
                            <div className="section mcb-section mcb-section-cf63py2da"
                                style={{
                                    paddingBottom: "70px"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-rbchf3tv7 one valign-top clearfix">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-0jpb3qyfl one column_column">
                                                <div className="column_attr clearfix align_center">
                                                    <h3>Frequently Asked Questions</h3>
                                                </div>
                                            </div>
                                            <div className="column mcb-column mcb-item-3ygm0ssk6 one column_column">
                                                <div className="column_attr clearfix">

                                                    <div className="faq">
                                                        <div className="mfn-acc faq_wrapper">
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">1</span>
                                                                    <i className="icon-plus acc-icon-plus"></i>
                                                                    <i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">
                                                                        How much is the anti-wrinkle & Dermal Filler course?
                                                                    </h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <p>
                                                                        Courses start from €2000, please enquire using contact page.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">2</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">
                                                                        Who is able to attend this course?
                                                                    </h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <p>
                                                                        Doctors and dentists are able to do the anti-wrinkle courses,nurses can attend the filler courses.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">3</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">
                                                                        Do I need to bring my own model and get my own insurance for the course?
                                                                    </h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <p>
                                                                        No, we provide everything for the course including models and cover on our insurance for the duration of the training.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">4</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">
                                                                        Can I practice after attending this course?
                                                                    </h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <p>
                                                                        Yes, after the training course you will receive a certificate and be able to get insured and thereafter, practice in the aesthetic medicine industry.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}

export default CoursesPage;