import React from 'react';
import DefaultLayout from '../../layout/DefautlLayout';
import Header from './../../components/header/Header';
import styled from './reviewsPage.module.css';

const ReviewsPage = () => {
    return (
        <DefaultLayout>
            <Header pagename="reviews" />
            <div id="Content">
                <div className="content_wrapper clearfix">
                    <div className="sections_group">
                        <div className="extra_content">
                            <div className="section mcb-section mcb-section-1186132e1 bg-cover"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    backgroundImage: "url(/assets/images/banners/reviews-xl-2.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "right bottom"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
                                        style={{
                                            padding: "30% 0 10%"
                                        }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
                                                <div className={`clearfix ${styled.bannerTextWidth}`}>
                                                    <h2>Reviews</h2>
                                                    <hr className="no_line"
                                                        style={{
                                                            margin: "0 auto 15px"
                                                        }} />
                                                    <p>
                                                        We love hearing what you have to say about your time with us at Sitara Medical Clinic. Here are some of the things people say about us.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styled.pusherDiv}></div>

                            <div className="section mcb-section" id="image"
                                style={{
                                    paddingBottom: "70px"
                                }}>
                                <div className="section_wrapper mcb-section-inner clearfix">
                                    <div className="wrap mcb-wrap one valign-top clearfix">
                                        <div className="mcb-wrap-inner" style={{ display: "flex", flexWrap: "wrap" }}>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/kate.brennan.39545/posts/2664656246928827" data-width="" data-show-text="true"><blockquote cite="https://www.facebook.com/kate.brennan.39545/posts/2664656246928827" className="fb-xfbml-parse-ignore"><p>Dr. Sana is a caring, knowledgable lady. I had previously gotten Botox in my upper lip to prevent my gummy smile/lip...</p>Posted by <a href="#" role="button">Kate Brennan</a> on&nbsp;<a href="https://www.facebook.com/kate.brennan.39545/posts/2664656246928827">Thursday, 24 October 2019</a></blockquote></div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/judith.b.walsh/posts/10222510935385205" data-width="" data-show-text="true"><blockquote cite="https://www.facebook.com/judith.b.walsh/posts/10222510935385205" className="fb-xfbml-parse-ignore"><p>Delighted with my results, Sana is very professional and talented, I couldn’t recommend her highly enough.</p>Posted by <a href="#" role="button">Judith Walsh</a> on&nbsp;<a href="https://www.facebook.com/judith.b.walsh/posts/10222510935385205">Sunday, 15 October 2017</a></blockquote></div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/ann.m.wallace1/posts/10204809336949763" data-width="" data-show-text="true"><blockquote cite="https://www.facebook.com/ann.m.wallace1/posts/10204809336949763" className="fb-xfbml-parse-ignore"><p>Dr Sana is very professional and does a great job,  she talks through the process step by step and explains as she goes,  and leaves Ya with great results,  I&#039;d highly recommend her work</p>Posted by <a href="https://www.facebook.com/ann.m.wallace1">Ann Marie Wallace</a> on&nbsp;<a href="https://www.facebook.com/ann.m.wallace1/posts/10204809336949763">Friday, 15 December 2017</a></blockquote></div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/erica.davin/posts/10220723955141277" data-width="" data-show-text="true"><blockquote cite="https://www.facebook.com/erica.davin/posts/10220723955141277" className="fb-xfbml-parse-ignore"><p>I highly recommend Sana for anti wrinkle injections. She&#039;s professional, skilled and I felt safe in her hands. Her technique is excellent with minimal discomfort. Thank you Sana! I&#039;ll see you soon...</p>Posted by <a href="#" role="button">Erica Davin</a> on&nbsp;<a href="https://www.facebook.com/erica.davin/posts/10220723955141277">Saturday, 10 February 2018</a></blockquote></div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/ashling.longbennett/posts/243948909812903" data-width="" data-show-text="true"><blockquote cite="https://www.facebook.com/ashling.longbennett/posts/243948909812903" className="fb-xfbml-parse-ignore"><p>Had my second visit with Sana. Had botox and lip fillers this time and i am more than pleased she made me feel so...</p>Posted by <a href="https://www.facebook.com/ashling.longbennett">Ashling Long Bennett</a> on&nbsp;<a href="https://www.facebook.com/ashling.longbennett/posts/243948909812903">Monday, 11 June 2018</a></blockquote></div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/sarah.mcdonald.3511/posts/10158786331016598" data-width="" data-show-text="true"><blockquote cite="https://www.facebook.com/sarah.mcdonald.3511/posts/10158786331016598" className="fb-xfbml-parse-ignore"><p>Dr. Sana did a Clef Lip Repair for me and I love the final results. I have a top lip now so I can wear lipstick and it looks good 😊.</p>Posted by <a href="https://www.facebook.com/sarah.mcdonald.3511">Sarah McDonald</a> on&nbsp;<a href="https://www.facebook.com/sarah.mcdonald.3511/posts/10158786331016598">Friday, 4 September 2020</a></blockquote></div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/sharonjit.virk/posts/10158636594034496" data-width="" data-show-text="true">
                                                        <blockquote cite="https://www.facebook.com/sharonjit.virk/posts/10158636594034496" className="fb-xfbml-parse-ignore"><p>Sana is an amazing clinician who is so talented! I love how she is so skilled at what she does.  She is patient and calm...</p>Posted by <a href="#" role="button">Sharonjit Kaur Virk</a> on&nbsp;<a href="https://www.facebook.com/sharonjit.virk/posts/10158636594034496">Sunday, 1 October 2017</a></blockquote>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one-second column_column">
                                                <div className="column_attr">
                                                    <div className="fb-post" data-href="https://www.facebook.com/anita.smithdonnelly/posts/10205260276343027" data-width="" data-show-text="true"><blockquote cite="https://www.facebook.com/anita.smithdonnelly/posts/10205260276343027" className="fb-xfbml-parse-ignore"><p>amazing clinic staff are fantastic so friendly and make u feel at ease ...i would 100 percent reccomend  Sitara for anyone 💖💖💖</p>Posted by <a href="https://www.facebook.com/anita.smithdonnelly">Anita Smith Donnelly</a> on&nbsp;<a href="https://www.facebook.com/anita.smithdonnelly/posts/10205260276343027">Wednesday, 21 November 2018</a></blockquote></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column mcb-column one column_column">
                                            <div className="column_attr align_center">
                                                <a className="button button_size_2 button_theme button_js" target="_blank"
                                                    href="https://www.facebook.com/SitaraMedicalClinic/reviews/">
                                                    <span className="button_label">
                                                        MORE FACEBOOK REVIEWS
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </DefaultLayout>
    );
}

export default ReviewsPage;
