import React from 'react';
import DefaultLayout from './../layout/DefautlLayout';
import Header from './../components/header/Header';


const ErrorPage = () => {
    return (
        <DefaultLayout>
            <Header />
            <div id="Content">
                <div className="content_wrapper clearfix">
                    <div className="sections_group">
                        <div className="extra_content">
                            <div className="section mcb-section mcb-section-1186132e1 bg-cover"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    backgroundImage: "url(./assets/images/insurance3-subheader3.jpg)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center bottom"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
                                        style={{
                                            padding: "30% 0 10%"
                                        }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-124fb3e6d one column_column">
                                                <div className="column_attr clearfix"
                                                    style={{
                                                        padding: "0 52% 0 0"
                                                    }}>
                                                    <h2>404</h2>
                                                    <hr className="no_line"
                                                        style={{
                                                            margin: "0 auto 15px"
                                                        }} />
                                                    <p>
                                                        Page Not Found
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="section center bg-theme hrmargin_b_60" style={{ paddingTop: "30px", paddingBottom: "15px" }}>
                                <div className="section_wrapper clearfix">
                                    <div className="items_group clearfix">
                                        <div className="column_attr">
                                            <h3 className="text-light" style={{ letterSpacing: "3px" }}>
                                                Facial Aesthetics Training with Dr. Sana Askary
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="section mcb-section" id="image"
                                style={{
                                    paddingBottom: "70px"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap one valign-top clearfix">
                                        <div className="mcb-wrap-inner">


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </DefaultLayout>
    );
}

export default ErrorPage;