import React from 'react';
import { Link } from 'react-router-dom';
import styled from './style.module.css';
import classNames from 'classnames/bind';


const MainSlider = () => {
    const cx = classNames.bind(styled);
    const className = cx({
        'mfn-main-slider': true,
        'bannerMB120': true
    })
    return (
        <div className={className} id="mfn-rev-slider">
            <p className="rs-p-wp-fix"></p>
            <rs-module-wrap id="rev_slider_1_1_wrapper" data-source="gallery"
                style={{ background: 'transparent', padding: 0, margin: '0 auto', marginTop: 0, marginBottom: 0 }}>
                <rs-module id="rev_slider_1_1" style={{ display: "none" }} data-version="6.1.2">
                    <rs-slides>
                        <rs-slide
                            data-key="rs-1"
                            data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;"
                        >
                            <img src="/assets/images/home/banner-1.jpg" className="rev-slidebg" data-no-retina alt="" />
                            <rs-layer
                                id="slider-1-slide-1-layer-2"
                                data-type="text"
                                data-color="#2e2e2e"
                                data-rsp_ch="on"

                                data-x="left"
                                data-y="top"
                                data-hoffset="[10, 20, 20, 20, 20]"
                                data-voffset="[350, 350, 443, 465, 550]"
                                data-width="[620, 620, 630, 630, 750]"

                                data-text="w:normal;s:75;s:75;s:75;l:80;"
                                data-frame_0="x:50;"
                                data-frame_1="st:200;sp:1000;sR:200;"
                                data-frame_999="o:0;st:w;sR:7800;"
                                style={{ zIndex: 9, fontFamily: "Minerva-Modern, DM Serif Display", padding: "20px 30px 20px", backgroundColor: '#e2e2e290' }}>
                                Experts in
                                <br /> Aesthetic Treatment
                            </rs-layer>
                            <rs-layer
                                id="slider-1-slide-1-layer-3"
                                data-type="text"
                                data-color="#5e5e5e"
                                data-rsp_ch="on"

                                data-x="left"
                                data-y="top"
                                data-hoffset="[10, 20, 20, 20, 20]"
                                data-voffset="[550, 550, 645, 665, 752]"
                                data-width="[620, 620, 630, 630, 750]"
                                data-fontsize="[20, 20, 22, 28, 36]"
                                data-lineheight="[34, 35, 35, 38, 38]"
                                data-text="w:normal"

                                data-frame_0="x:50;"
                                data-frame_1="st:300;sp:1000;sR:300;"
                                data-frame_999="o:0;st:w;sR:7700;"

                                style={{ zIndex: 10, fontFamily: "DM Sans", padding: "20px 30px 20px", backgroundColor: '#e2e2e290' }}>
                                At Sitara Clinic, we emphasis on the care
                                for our clients and delivering high quality results.
                                With several clinics through Ireland, we are dedicated to being the go-to clinic for aesthetic excellence in anti-wrinkle treatments & dermal fillers.
                            </rs-layer>
                            <Link to="/contact-us">
                                <rs-layer
                                    id="slider-1-slide-1-layer-4"
                                    className="rev-btn"
                                    data-type="button"
                                    data-color="#ffffff"
                                    data-rsp_ch="on"
                                    data-x="left"
                                    data-y="top"
                                    data-hoffset="[310, 310, 306, 330]"
                                    data-voffset="[800, 800, 890, 975, 1070]"
                                    data-text="w:normal;l:60;ls:0px;fw:500;"
                                    data-fontsize="[22, 22, 22, 28, 30]"
                                    data-paddingtop="[0,0,10,15]"
                                    data-paddingbottom="[0,0,10,15]"
                                    data-dim="minh:0px;"
                                    data-padding="r:40;l:40;"
                                    data-border="bor:30px,30px,30px,30px;"
                                    data-frame_0="x:50;"
                                    data-frame_1="st:500;sp:1000;sR:500;"
                                    data-frame_999="o:0;st:w;sR:7500;"
                                    data-frame_hover="bgc:#dcbae3;bor:30px,30px,30px,30px;sp:200;e:Power1.easeInOut;"
                                    style={{ zIndex: 12, backgroundColor: "var(--bg-grey)", fontFamily: "DM Sans" }}>
                                    CONTACT US
                                </rs-layer>
                            </Link>
                            <Link to="/book-now">
                                <rs-layer id="slider-1-slide-1-layer-5"
                                    className="rev-btn"
                                    data-type="button"
                                    data-color="#ffffff"
                                    data-rsp_ch="on"

                                    data-x="left"
                                    data-y="top"
                                    data-hoffset="[31, 31, 31, 31]"
                                    data-voffset="[800, 800, 890, 975, 1070]"

                                    data-text="w:normal;l:60;ls:0px;fw:500;"
                                    data-fontsize="[22, 22, 22, 28, 30]"
                                    data-paddingtop="[0,0,10,15]"
                                    data-paddingbottom="[0,0,10,15]"

                                    data-dim="minh:0px;"
                                    data-padding="r:40;l:40;"
                                    data-border="bor:30px,30px,30px,30px;"
                                    data-frame_0="x:50;"
                                    data-frame_1="st:400;sp:1000;sR:400;"
                                    data-frame_999="o:0;st:w;sR:7600;"
                                    data-frame_hover="bgc:#9e9e9e;bor:30px,30px,30px,30px;sp:200;e:Power1.easeInOut;"
                                    style={{ zIndex: "11", backgroundColor: 'var(--bg-theme)', fontFamily: "DM Sans" }}>
                                    BOOK NOW
                                </rs-layer>
                            </Link>
                        </rs-slide>
                    </rs-slides>
                    <rs-progress className="rs-bottom" style={{ visibility: "hidden !important" }}></rs-progress>
                </rs-module>
            </rs-module-wrap>
        </div>
    );
}

export default MainSlider;
