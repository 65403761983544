import BlankLayout from '../../layout/BlankLayout';
import Header from '../../components/header/Header';
import styled from './style.module.css';


const VouchersPage = (props) => {
    return (

        <BlankLayout>
            <Header />
            <div className={styled.wrapperDiv}>
                <iframe title="booking iframe" src="https://gift-cards.phorest.com/salons/sitaramedicalcliniccarlow" width="100%" style={{ minHeight: "100%" }}></iframe>
            </div>
        </BlankLayout>
    );
}

export default VouchersPage;