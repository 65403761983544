import React from 'react';
import DefaultLayout from '../../../layout/DefautlLayout';
import Header from '../../../components/header/Header';
import styled from './style.module.css';

import { Link } from 'react-router-dom';

const AntiWrinklePage = () => {
	return (
		<DefaultLayout>
			<Header />
			<div id="Content">
				<div className="content_wrapper clearfix">
					<div className="sections_group">
						<div className="extra_content">
							<div
								className={`section mcb-section mcb-section-1186132e1 bg-cover`}
								style={{
									paddingTop: '0px',
									paddingBottom: '0px',
									backgroundImage: 'url(/assets/images/banners/treatment-xl-2.png)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'right bottom',
								}}>
								<div className="section_wrapper mcb-section-inner">
									<div
										className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
										style={{
											padding: '30% 0 0%',
										}}>
										<div className="mcb-wrap-inner">
											<div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
												<div className={`clearfix ${styled.bannerTextWidth}`}>
													<h2>Anti-wrinkle injections</h2>
													<hr
														className="no_line"
														style={{
															margin: '0 auto 15px',
														}}
													/>
													<p>
														Botulinum Toxin is a purified protein which can be injected into facial muscles for anti-ageing
														purposes. This treatment can be preventative as well as to treat existing wrinkles. It works by relaxing
														over-active muscles of the face.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className={styled.pusherDiv}></div>

							<div className="section center bg-theme hrmargin_b_60" style={{ paddingTop: '30px', paddingBottom: '15px' }}>
								<div className="section_wrapper clearfix">
									<div className="items_group clearfix">
										<div className="column_attr">
											<h3 className="text-light" style={{ letterSpacing: '3px' }}>
												“ The best wrinkle is the one you never get. ”
											</h3>
										</div>
									</div>
								</div>
							</div>

							<div
								className="section mcb-section"
								id="image"
								style={{
									paddingBottom: '70px',
								}}>
								<div className="section_wrapper mcb-section-inner">
									<div className="wrap mcb-wrap one valign-top clearfix">
										<div className="mcb-wrap-inner">
											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<p>
														The most common areas to treat are the forehead, frown, crows feet, bunny lines, smoker lines and corner
														of the mouth. Botulinum toxin is also used to treat migraines, teeth grinding, excessive sweating (face
														or underarm), platysmal neck bands, and gummy smile. The results take 2 weeks to take effect, and
														generally lasts 3-4 months.
													</p>
												</div>
											</div>

											<div className="column mcb-column one-third column_image">
												<div className="image_frame image_item scale-with-grid aligncenter has_border hrmargin_b_30">
													<div className="image_wrapper">
														<div className="mask"></div>
														<img
															className="scale-with-grid"
															src="/assets/images/treatments/anti-wrinkle/image1.jpg"
															alt="home_betheme_blog_masonry_1"
															width="960"
															height="960"
														/>
													</div>
												</div>
											</div>
											<div className="column mcb-column two-third column_column">
												<div className="column_attr clearfix">
													<h4>WHAT ARE ANTI-WRINKLE INJECTIONS?</h4>
													<p>
														Fine lines under the eyes, Crow's feet, and forehead wrinkles are all dreaded signs of ageing.
														Anti-wrinkle injections, will help by giving you a well-rested, youthful appearance that is subtle yet
														powerful. We can't turn back the hands of time, however we can slow down the ageing process.
														Anti-wrinkle injections are in the forefront of anti-aging procedures worldwide. Anti-wrinkle injections
														obstruct the impulses between the nerves and the muscles, allowing the muscles to contract when
														administered in small amounts into selected locations. They reduce the presence of lines to give you a
														more young, modern, and, most importantly, natural look.
													</p>
													<h4>Why choose Sitara Clinic for Anti-Wrinkle injections?</h4>
													<ul>
														<li>Led by Doctors/Dentists</li>
														<li>Specialize in Anti-Wrinkle injections - this is what we do best.</li>
														<li>Performed hundreds and thousands of treatments</li>
														<li>Safe and painless treatments</li>
														<li>Free consultations and aftercare advice</li>
													</ul>
												</div>
											</div>
											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<div className="column one column_pricing_item">
														<div className="pricing-box custom-pricing-box pricing-box-table pricing-box-featured">
															<div className="plan-header">
																<h3>Pricing</h3>
															</div>

															<div className="plan-inside clearfix">
																<hr className="bg-theme" />
																<ul>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Anti-Wrinkle Injections 1 Area</strong>
																		</div>
																		<div>
																			Dr Sana - €170/£150 <br />
																			Injectors - €170
																		</div>
																	</li>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Anti-Wrinkle Injections 2 Areas</strong>
																		</div>
																		<div>
																			Dr Sana - €250/£170 <br />
																			Injectors - €225/£190
																		</div>
																	</li>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Anti-Wrinkle Injections 3 Areas</strong>
																		</div>
																		<div>
																			Dr Sana - €300/£260 <br />
																			Injectors - €260/£220
																		</div>
																	</li>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Anti-Wrinkle Injections 4 Areas</strong>
																		</div>
																		<div>
																			Dr Sana - €300/£260 <br />
																			Injectors - €280/£240
																		</div>
																	</li>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Any additional area</strong>
																		</div>
																		<div>
																			Dr Sana - €20/£17 <br />
																			Injectors - €20/£17
																		</div>
																	</li>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Botulinum Toxin for teeth grinding</strong>
																		</div>
																		<div>
																			Dr Sana - €300/£260 <br />
																			Injectors - €260/£220
																		</div>
																	</li>
																	<li className="clearfix" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
																		<div>
																			<strong>Botulinum Toxin for excessive sweating</strong>
																		</div>
																		<div>
																			Dr Sana - €300/£260 <br />
																			Injectors - €260/£220
																		</div>
																	</li>
																</ul>
																<hr className="bg-theme" />
															</div>

															<div className="plan-footer">
																<Link to="/book-now" className="button  button_left button_theme button_js">
																	<span className="button_icon">
																		<i className="icon-basket"></i>
																	</span>
																	<span className="button_label">BOOK NOW</span>
																</Link>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column one column_slider">
												<div className="column_attr clearfix hrmargin_b_60">
													<h2
														className="hrmargin_b_60"
														style={{
															textAlign: 'center',
														}}>
														Results
													</h2>

													<div className="content_slider">
														<ul className="content_slider_ul">
															<li className="content_slider_li_1">
																<img
																	width="100%"
																	height="auto"
																	src="/assets/images/treatments/slider/anti-wrinkle-1.jpg"
																	className="scale-with-grid wp-post-image"
																	alt="Anti Wrinkle Injection"
																/>
															</li>
														</ul>
													</div>
												</div>
											</div>

											<div className="column mcb-column one column_divider">
												<hr className="no_line" />
											</div>

											<div className="column mcb-column one column_column">
												<div className="column_attr clearfix">
													<h2
														className="hrmargin_b_60"
														style={{
															textAlign: 'center',
														}}>
														After-Care
													</h2>
													<ul>
														<li>
															Following your treatment, you may experience any red bumps at the injection sites, which should
															subside within an hour.
														</li>
														<li>After the injection, you do not apply any pressure to the treated region for at least 8 hours.</li>
														<li>After the procedure, you must remain standing for 4 hours.</li>
														<li>For a minimum of 24 hours after surgery, no make-up can be worn in the treated area.</li>
														<li>
															For the first 24 hours after recovery, stay away from alcohol, strenuous exercise, and high
															temperatures.
														</li>
														<li>
															If you ever have a bruise, it can take a few days to appear and up to ten days to heal. Arnica can
															aid in the healing of bruises. Ibuprofen and alcohol must be avoided for the first 24-48 hours of
															surgery because they can cause swelling.
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default AntiWrinklePage;
