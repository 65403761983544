import React from 'react';
import Footer from '../components/footer/Footer';

const defautlLayout = ({ children }) => {
    return (
        <>
            <div id="Wrapper">

                {children}

                <Footer />
            </div>

            <div id="Side_slide" className="right dark" data->
                <div className="close-wrapper">
                    <a href="#" className="close"><i className="icon-cancel-fine"></i></a>
                </div>
                <div className="extras">
                    <div className="extras-wrapper"></div>
                </div>
                <div className="menu_wrapper"></div>
            </div>
            <div id="body_overlay"></div>
        </>
    );
}

export default defautlLayout;
