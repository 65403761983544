// import { lazy, Suspense } from 'react';
import Routes from './app/views/Routes';
import './App.css';

// const LazyLoadingPages = lazy(() => Routes);

function App() {
  return (
    <Routes />
  );
}

export default App;
