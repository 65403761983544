import React, { useState } from 'react';
import { useFormik } from 'formik';
import { contactUs } from './../../services/emailServices';
import errorConstants from './../../models/errorConstants.json';
import DefaultLayout from '../../layout/DefautlLayout';
import Header from './../../components/header/Header';
import styled from './contactPage.module.css';

const validateRegisterForm = values => {
    const errors = {};

    if (!values.name) {
        errors.name = `${errorConstants.FULL_NAME_MISSING}`;
    } else if (!/^[A-Za-z\s]+$/i.test(values.name)) {
        errors.name = `${errorConstants.INVALID_CHARACTERS}`;
    }

    if (values.phoneNo) {
        if (!/^[0-9]+$/.test(values.phoneNo)) {
            errors.phoneNo = `${errorConstants.PHONE_INVALID}`;
        }
    }

    if (!values.email) {
        errors.email = `${errorConstants.EMAIL_MISSING}`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = `${errorConstants.EMAIL_INVALID}`;
    }

    if (!values.message) {
        errors.message = `${errorConstants.MESSAGE_MISSING}`;
    }

    return errors;
};

const ContactPage = () => {

    const [disableForm, changeFormStatus] = useState(0);

    const [formStatus, updateServerResponse] = useState("");

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            phoneNo: '',
            message: ''
        },
        validate: validateRegisterForm,
        onSubmit: ({
            email,
            name,
            phoneNo,
            message }) => {

            contactUs({
                email,
                name,
                phoneNo,
                message
            })
                .then(result => {
                    changeFormStatus(1);
                    updateServerResponse("Email sent successfully");
                })
                .catch(error => {
                    changeFormStatus(0);
                    updateServerResponse("Email not sent. Check all the fields.");
                });
        },
    });

    return (
        <DefaultLayout>
            <Header />
            <div id="Content">
                <div className="content_wrapper clearfix">
                    <div className="sections_group">
                        <div className="extra_content">
                            <div className="section mcb-section mcb-section-1186132e1 bg-cover"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    backgroundImage: "url(/assets/images/banners/contact-us-xl-2.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "right bottom"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-3d0d51bb1 one valign-top clearfix"
                                        style={{
                                            padding: "30% 0 10%"
                                        }}>
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-124fb3e6d one-second column_column">
                                                <div className={`clearfix ${styled.bannerTextWidth}`}>
                                                    <h2>Contact Us</h2>
                                                    <hr className="no_line"
                                                        style={{
                                                            margin: "0 auto 15px"
                                                        }} />
                                                    <p>
                                                        Have any questions? We'd love to hear from you and aim to get back to you within 24 hours or less.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styled.pusherDiv}></div>

                            <div className="section mcb-section mcb-section-cf63py2da"
                                style={{
                                    paddingBottom: "70px"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-rbchf3tv7 one valign-top clearfix">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-0jpb3qyfl one column_column">
                                                <div className="column_attr clearfix align_center">
                                                    <h3>Locations</h3>
                                                </div>
                                            </div>
                                            <div className="column mcb-column mcb-item-3ygm0ssk6 one column_column">
                                                <div className="column_attr clearfix">
                                                    <div className="faq">
                                                        <div className="mfn-acc faq_wrapper">
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">1</span>
                                                                    <i className="icon-plus acc-icon-plus"></i>
                                                                    <i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">Dublin</h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <iframe title="map-1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2385.6835381872843!2d-6.218877234008123!3d53.27728963758238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486709095100f7dd%3A0xba0bc373852042dd!2sThe%20Cubes%203!5e0!3m2!1sen!2sin!4v1622066138024!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy"></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">2</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">Carlow</h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <iframe title="map-2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2410.2744381250714!2d-6.92696038402046!3d52.83543972055616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485d5dc43a1e7f43%3A0x1a6da5ff169977a9!2sSitara%20Medical%20Clinic%20Carlow!5e0!3m2!1sen!2sin!4v1622066721359!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy"></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">3</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">Waterford</h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <iframe title="map-3" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12913.351791697552!2d-7.115768450686523!3d52.25750860730977!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486773dc17dd3cb9%3A0x5f993d0e7d10c5e7!2sSitara%20Medical%20Clinic%20Waterford%3A%20Botox%20%26%20Filler%20clinic!5e0!3m2!1sen!2sin!4v1622066861939!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy"></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">4</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">Wexford</h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <iframe title="map-4" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d604.7808843702204!2d-6.291870670730872!3d52.67580691188455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4867e7a007098e49%3A0x4b8d3cf34198a07b!2sSitara%20Medical%20Clinic%20Gorey%20-%20Botox%2C%20Filler%2C%20Lips%2C%20Threads%2C%20Facelift!5e0!3m2!1sen!2sin!4v1622067103251!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy"></iframe>
                                                                </div>
                                                            </div>
                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">5</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">Clonmel</h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <iframe title="map-5" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.7750503159846!2d-7.740326484033762!3d52.35636505609542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4843318fa9c6ffc7%3A0xe6fad7af2a54528d!2sClonmel%20Park%20Hotel!5e0!3m2!1sen!2sin!4v1622067689884!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy"></iframe>
                                                                </div>

                                                            </div>

                                                            <div className="question">
                                                                <div className="title text-dark bg-theme05">
                                                                    <span className="num">6</span><i className="icon-plus acc-icon-plus"></i><i className="icon-minus acc-icon-minus"></i>
                                                                    <h5 className="hrmargin_b_1">Newry</h5>
                                                                </div>
                                                                <div className="answer">
                                                                    <iframe title="map-6" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d583.7815457198304!2d-6.339575670728206!3d54.177841083670245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4860dbcf1bae5405%3A0x77dc9345f09eb251!2sSitara%20Medical%20Clinic%20Newry%20-%20Botox%2C%20Dermal%20Filler%2C%20Lip%20Filler%2C%20Thread-lift%2C%20Non-Surgical%20Facelift!5e0!3m2!1sen!2sin!4v1622067788674!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>







                            <div className="section mcb-section mcb-section-cf63py2da"
                                style={{
                                    paddingTop: "7px",
                                    paddingBottom: "70px"
                                }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="wrap mcb-wrap mcb-wrap-rbchf3tv7 one valign-top clearfix">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column mcb-item-0jpb3qyfl one column_column">
                                                <div className="column_attr clearfix align_center">
                                                    <h3>Send us a message</h3>
                                                </div>
                                            </div>
                                            <div className="column mcb-column mcb-item-3ygm0ssk6 one-sixth column_column"></div>
                                            <div className="column mcb-column mcb-item-3ygm0ssk6 two-third column_column">
                                                <div className="column_attr clearfix">
                                                    <div id="contactWrapper">
                                                        {
                                                            formStatus ?
                                                                <div className={"alert alert_" + (disableForm ? "success" : "error")}>
                                                                    <div className={"alert_icon icon-" + (disableForm ? "lamp" : "check")}></div>
                                                                    <div className="alert_wrapper">
                                                                        {formStatus}
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        <form onSubmit={formik.handleSubmit}>

                                                            <div className="column one-second">
                                                                <input
                                                                    className={formik.touched.name && formik.errors.name ? "text-error" : ""}
                                                                    placeholder="Your name *"
                                                                    type="text"
                                                                    name="name"
                                                                    id="name"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    value={formik.values.name}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                <div className={formik.touched.name && formik.errors.name ? "message text-error" : "message"}>
                                                                    {formik.errors.name}
                                                                </div>

                                                            </div>

                                                            <div className="column one-second">
                                                                <input
                                                                    className={formik.touched.email && formik.errors.email ? "text-error" : ""}
                                                                    placeholder="Your e-mail *"
                                                                    type="email"
                                                                    name="email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    value={formik.values.email}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                <div className={formik.touched.email && formik.errors.email ? "message text-error" : "message"}>
                                                                    {formik.errors.email}
                                                                </div>

                                                            </div>
                                                            <div className="column one">
                                                                <input
                                                                    className={formik.touched.phoneNo && formik.errors.phoneNo ? "text-error" : ""}
                                                                    placeholder="Phone Number"
                                                                    type="number"
                                                                    name="phoneNo"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    value={formik.values.phoneNo}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                <div className={formik.touched.phoneNo && formik.errors.phoneNo ? "message text-error" : "message"}>
                                                                    {formik.errors.phoneNo}
                                                                </div>
                                                            </div>
                                                            <div className="column one">

                                                                <textarea className={formik.touched.message && formik.errors.message ? "text-error" : ""} placeholder="Message *"
                                                                    name="message"
                                                                    style={{ width: "100%" }}
                                                                    rows="10"
                                                                    aria-invalid="false"
                                                                    value={formik.values.message}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                ></textarea>
                                                                <div className={formik.touched.message && formik.errors.message ? "message text-error" : "message"}>
                                                                    {formik.errors.message}
                                                                </div>
                                                            </div>
                                                            <div className="column one">
                                                                <button className="disabled" style={{ width: "100%" }} disabled={disableForm ? "disabled" : ""} type="submit">
                                                                    SEND MESSAGE
                                                                </button>
                                                            </div>
                                                        </form>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}

export default ContactPage;